import React, {useState} from "react";
import {fetcher, URL_STATS_CONCLUSIONS} from "../../../utils/fetcher";
import useSWR from 'swr';
import {toBrowserDate} from "../../../utils/utils";
import {connect} from "react-redux";

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const StatisticsConclusions = props =>  {

    const { token } = props;

    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());

    const updateDateToHandler = (value) => {
        if (!dateFrom) {
            setDateFrom(value)
        }
        setDateTo(value)
    }

    const { data } = useSWR([`${URL_STATS_CONCLUSIONS}${dateFrom ? '?from=' + toBrowserDate(dateFrom) : ''}${dateTo ? '&to=' + toBrowserDate(dateTo) : ''}`, token], fetcher)

    const chatsData = data?.result ? data.result.map(el => {
        return {
            ...el,
            chatId: el.chatId._id
        }
    }) : [];

    let chatsDataByDoctor = []
    chatsData.forEach(conclusion => {
        const { doctorId, specialization } = conclusion
        const exists = chatsDataByDoctor.find(el => el.doctorId === doctorId)
        if (exists) {
            exists.conclusions += 1;
        } else {
            chatsDataByDoctor.push({
                doctorId,
                specialization,
                conclusions: 1,
            })
        }
    })

    return (
        <div className='mt-20'>
            <h5>Заключения</h5>
            <div className="mt-15">
                <div>Дата от:</div>
                <div className="wp-100 mt-5">
                    <input type="date" className="wp-100" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                </div>
            </div>
            <div className="mt-15">
                <div>Дата до:</div>
                <div className="wp-100 mt-5">
                    <input type="date" className="wp-100" value={dateTo} onChange={(e) => updateDateToHandler(e.target.value)} />
                </div>
            </div>

            <div className='separator mt-10' />

            <div className="mt-20">
                <p className='mt-10'>{`Всего заключений: ${chatsData?.length || 0}`}</p>

                {chatsData?.length &&
                    <div className="mt-20">
                        <div className="mt-20">
                            <p>Скачать данные по заключениям:</p>
                            <ExcelFile>
                                <ExcelSheet data={chatsData} name="Conclusions">
                                    <ExcelColumn label="ID специалиста" value="doctorId"/>
                                    <ExcelColumn label="ID чата" value="chatId"/>
                                    <ExcelColumn label="Время" value="time"/>
                                    <ExcelColumn label="Специализация" value="specialization"/>
                                    <ExcelColumn label="Заключение" value="conclusion"/>
                                    <ExcelColumn label="Организация" value="organizationName"/>
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                        <div className="mt-20">
                            <p>Скачать данные по заключениям (по специалисту):</p>
                            <ExcelFile>
                                <ExcelSheet data={chatsDataByDoctor} name="Rating">
                                    <ExcelColumn label="ID специалиста" value="doctorId"/>
                                    <ExcelColumn label="Специализация" value="specialization"/>
                                    <ExcelColumn label="Заключений" value="conclusions"/>
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    </div>
                }

            </div>

        </div>
    );
}

const mapStateToProps = ({  data: { token }}) => {
    return { token }
};


export default connect(mapStateToProps)(StatisticsConclusions);
