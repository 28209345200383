import React, {useState} from "react";
import {fetcher, URL_STATS_RATING} from "../../../utils/fetcher";
import useSWR from 'swr';
import {toBrowserDate} from "../../../utils/utils";
import {connect} from "react-redux";

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const StatisticsFeedback = props =>  {

    const { token } = props;

    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());

    const updateDateToHandler = (value) => {
        if (!dateFrom) {
            setDateFrom(value)
        }
        setDateTo(value)
    }

    const { data } = useSWR([`${URL_STATS_RATING}${dateFrom ? '?from=' + toBrowserDate(dateFrom) : ''}${dateTo ? '&to=' + toBrowserDate(dateTo) : ''}`, token], fetcher)

    const chatsData = data ? data.result : [];

    return (
        <div className='mt-20'>
            <h5>Отзывы</h5>
            <div className="mt-15">
                <div>Дата от:</div>
                <div className="wp-100 mt-5">
                    <input type="date" className="wp-100" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                </div>
            </div>
            <div className="mt-15">
                <div>Дата до:</div>
                <div className="wp-100 mt-5">
                    <input type="date" className="wp-100" value={dateTo} onChange={(e) => updateDateToHandler(e.target.value)} />
                </div>
            </div>

            <div className='separator mt-10' />

            <div className="mt-20">
                <p className='mt-10'>{`Отзывов: ${chatsData.length || 0}`}</p>

                {chatsData?.length &&
                    <div className="mt-20">
                        <p>Скачать данные по отзывам:</p>
                        <ExcelFile>
                            <ExcelSheet data={chatsData} name="Rating">
                                <ExcelColumn label="ID специалиста" value="doctorId"/>
                                <ExcelColumn label="ID чата" value="chatId"/>
                                <ExcelColumn label="Время" value="time"/>
                                <ExcelColumn label="Специализация" value="specialization"/>
                                <ExcelColumn label="Звезды" value="feedbackRating"/>
                                <ExcelColumn label="Отзыв" value="feedbackText"/>
                                <ExcelColumn label="Чат" value="chatUrl"/>
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                }

            </div>

        </div>
    );
}

const mapStateToProps = ({  data: { token }}) => {
    return { token }
};


export default connect(mapStateToProps)(StatisticsFeedback);
