import React from "react";
import { fetcher, URL_STATS_CLIENTS } from "../../../utils/fetcher";
import useSWR from 'swr';
import {connect} from "react-redux";
import StatisticsChats from "./statistics-chats";
import StatisticsPayments from "./statistics-payments";
import StatisticsFeedback from "./statistics-feedback";
import StatisticsConclusions from "./statistics-conslusions";

const Statistics = props =>  {

    const { token } = props;

    const { data } = useSWR([URL_STATS_CLIENTS, token], fetcher)

    const clientsData = data ? data.result : {};


    return (
        <div className="tile">
            <h5>Клиенты:</h5>
            <div className="mt-20">
                <p>{`Всего клиентов: ${clientsData.clients || 0}`}</p>
            </div>

            <div className='separator mt-10' />

            <StatisticsChats />

            <div className='separator mt-10 mb-30' />

            {process.env.REACT_APP_THEME !== 'legal' && <StatisticsPayments />}

            <div className='separator mt-10 mb-30' />

            <StatisticsConclusions />

            <div className='separator mt-10 mb-30' />

            <StatisticsFeedback />

        </div>
    );
}

const mapStateToProps = ({  data: { token }}) => {
    return { token }
};


export default connect(mapStateToProps)(Statistics);
