const serverUrl = process.env.REACT_APP_API_URL

export const URL_STATS_CHATS = '/statistics/chats';
export const URL_STATS_PAYMENTS = '/statistics/payments';
export const URL_STATS_RATING = '/statistics/rating';
export const URL_STATS_CONCLUSIONS = '/statistics/conclusions';
export const URL_STATS_CLIENTS = '/statistics/clients';

export const fetcher = (url, token) => fetch(`${serverUrl}${url}`, {
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`
    },}).then(r => r.json())
